import React from 'react';
import {Link} from "react-router-dom";
import styles from './style.css'

const Header = () => 
{
    return (
        <div className="header">
            <Link to={{pathname: "/"}}>
                <div className="logo"></div>
            </Link>
            <Link to={{pathname: "/tourguide1/"}}>
                <div className="tourguide"></div>
            </Link>  
            <Link to={{pathname: "/tourguide1/"}}>
                <div className="become-tourguide"></div>
            </Link>                         
        </div>
    )
}

export default Header;