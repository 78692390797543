import React from 'react';
import "./style.css";

const Chekbox = ({type, label}) => {
  return(
    <label className={`ra-checkbox style-${type||'1'}`}>{label}<input type="checkbox" /><div className="f-chk"></div></label>

  )
};

export default Chekbox;
