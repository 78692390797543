import React, {useState, useCallback, useRef} from 'react';
import "./style.css";

const Counter = ({ label, labelWidth, view='default'}) => {
  const [counter,setCounter] = useState(0);
  
  if(view=="form-counter"){
    return(
      <div className={`counter ${view}`}>
          <i className="minus" onClick={(e)=>{setCounter(counter>0?counter-1:0)}}>-</i>
          <input style={labelWidth?{"width" : labelWidth}:{}} value={counter==0?label:counter} readOnly/>
          <i className="plus" onClick={(e)=>{setCounter(counter+1)}}>+</i>
      </div>
    )
  }
  
  return(
    <div className={`counter ${view}`}>
        <div style={labelWidth?{"width" : labelWidth}:{}}>{label}</div><i className="fas fa-minus-circle" onClick={(e)=>{setCounter(counter>0?counter-1:0)}}></i><input value={counter} readOnly/><i className="fas fa-plus-circle" onClick={(e)=>{setCounter(counter+1)}}></i>
    </div>
  )
};

export default Counter;
