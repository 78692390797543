import React, { useState, useCallback } from 'react';
import {Link} from "react-router-dom";
import style from './TourGuide.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import TourGuidePage_04 from './assets/TourGuidePage_04.jpg'
import Switch from '@material-ui/core/Switch';

import Label from './components/Form/Label';
import Counter from './components/Form/Counter';
import TextField from './components/Form/TextField';
import Select from './components/Form/Select';
import Checkbox from './components/Form/Checkbox';
import TextArea from './components/Form/TextArea';
import Phone from './components/Form/Phone';
import File from './components/Form/File';
import Button from './components/Form/Button';

const TourGuide04 = () => 
{
    const [file, addFile] = useState([" "]);
    const [test, addTest] = useState(true);

    const [tourHours, addTourHours] = useState({"Monday":true,"Tuesday":true,"Wednsday":true,"Thursday":true,"Friday":true,"Saturday":true,"Sunday":true});
    const hours = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
    const categories = ["","Art","Fashion","Food","Wine"];
    const sub_categories = ["","Healthy","Markets","Sweets","Vegan"];
    const specialities = ["","Cakes","Chocolate","Newest","Street Food"];


    const onAddFile = useCallback((item)=>{        
        addFile(l=>[...l,...[item]]);
    },[]);

    const onToggleHours = useCallback((item, checked)=>{
        addTourHours(l=>{l[item] = checked; return Object.assign({}, l);})
    },[]);

    return (
        <div className="tourguide">
            <Header/>
            <HeroResults area="tourguide"/>
            <div className="page">
            <div className="form03">
                    <div className="right-bar">
                        <Checkbox type={"2"} label={"Kids (under 16)"}/>
                        <Checkbox type={"2"} label={"Senior (above 65)"}/>
                        <Checkbox type={"2"} label={"Disabled"}/>
                        <Label css={{"width":"200px"}}>Number of Participants</Label>
                        <Select type="2" css={{"width":"200px"}} values={["One on One","Group"]}/>
                        <Label css={{"width":"200px"}}>Tour Duration</Label>
                        <Select type="2" css={{"width":"200px"}} values={["1-2 Hours","2-4 Hours", "4 Hours or more"]}/>

                    </div>
                    <div className="row">
                        <div className="cell first">Tour Name:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Tour Description:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Long Description:</div><div className="cell second"><TextArea type="2" css={{"width":"450px", "height" : "180px" }}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Upload Photos:</div>
                        <div className="cell second">
                        {file.map((i,k)=>{
                            return (<File key={k} type="2" css={{"width":"450px"}}/>)

                        })}
                        <a className="cta" href="javascript:void(0)" onClick={onAddFile}>Upload More Photos</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cell first">Video:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}} placeholder={"Add a link from YouTube or Vimeo"}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Tour Price:</div><div className="cell second">
                            <TextField type="2" css={{"width":"220px"}}/>
                            <Label type="2">Currency&nbsp;&nbsp;</Label>
                            <Select type="2" css={{"width":"140px"}} values={["USD","ILS","GBP"]}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cell first">Category:</div><div className="cell second"><Select type="2" css={{"width":"300px"}} values={categories}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Sub Category:</div><div className="cell second"><Select type="2" css={{"width":"300px"}} values={sub_categories}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Speciality:</div><div className="cell second"><Select type="2" css={{"width":"300px"}} values={specialities}/></div>
                    </div>
                    <div className="row">
                        <h4>Available Hours</h4>


                    </div>
                    {Object.keys(tourHours).map((i,k)=>{
                        let checked = tourHours[i];
                        return <div className="row hours" key={k}>
                            <div className="cell first">{i}</div>
                            <div className="cell second">
                                <Switch
                                    checked={tourHours[i]}
                                    onChange={(e, checked)=>{onToggleHours(i, checked)}}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Label type={"3"}>{tourHours[i]?"Available":"N/A"}</Label>
                                {tourHours[i] && <><Select type="3" css={{"width":"150px"}} values={hours}/>-<Select type="3" css={{"width":"150px","marginLeft":"20px"}} values={hours}/><a href="javascript:void(0)" className="cta">Add Hours</a></>}
                                
                            </div>
                        </div>

                    })}

                    <div className="row">
                        <div className="cell first">&nbsp;</div><div className="cell second submit">
                            <Button type="3" css={{"width":"280px", "marginRight" : "25px","marginLeft" : "15px"}} pathname="/tourguide4" label="Add More Tours"/>
                            <Button type="2" css={{"width":"280px"}} pathname="/tourguide5" label="Continue to Final Step"/>
                        </div>
                    </div>


                </div>       
                <img src={TourGuidePage_04}/>

            </div>
            <Footer/>
        </div>
    )
}

export default TourGuide04;