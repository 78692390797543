import React from 'react';
import "./style.css";

const Label = (props) => {
  return(
    <label className={`ra-label style-${props.type||'1'}`} style={props.css}>{props.children}</label>
  )
};

export default Label;
