import React from 'react';
import {Link} from "react-router-dom";
import style from './TourGuide.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import TourGuidePage_05 from './assets/TourGuidePage_05.jpg'
import Checkbox from './components/Form/Checkbox';
import Button from './components/Form/Button';

const TourGuide05 = () => 
{
    return (
        <div className="tourguide">
            <Header/>
            <HeroResults area="tourguide"/>
            <div className="page">
                <div className="form04">
                    <Checkbox type={"2"} label={"I confirm all details are correct and I'm not crazy"}/>
                    <br/>
                    <Button type="2" css={{"width":"180px"}} pathname="/tourguide6" label="PUBLISH"/>
                </div>

                <img src={TourGuidePage_05}/>
            </div>
            <Footer/>
        </div>
    )
}

export default TourGuide05;