import React from 'react';
import "./style.css";

function selectFile(e)
{
  e.target.parentNode.querySelector('input[type="file"]').click();
}

function selectedFile(e)
{
  let fileName = e.target.value.split('\\').pop();
  e.target.parentNode.querySelector('input[type="text"]').value = fileName;
}

const File = ({type, css, placeholder}) => {
  return(
    <div className={`ra-file style-${type||'1'}`} style={css} placeholder={placeholder}>
        <input type="text" placeholder={placeholder}/>
        <button onClick={selectFile}>Browse</button>
        <input type="file" onChange={selectedFile}/>
    </div>
  )
};

export default File;
