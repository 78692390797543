import React ,{useCallback, useState, useRef}from 'react';
import {Link} from "react-router-dom";
import style from './TourGuide.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import TourGuidePage_02 from './assets/TourGuidePage_02.jpg'
import Photo from './assets/photo.png'

import Label from './components/Form/Label';
import Counter from './components/Form/Counter';
import TextField from './components/Form/TextField';
import Select from './components/Form/Select';
import Checkbox from './components/Form/Checkbox';
import TextArea from './components/Form/TextArea';
import Phone from './components/Form/Phone';
import Button from './components/Form/Button';

import search_values from './components/SearchEngine/data/search_engine.json';

const TourGuide02 = () => 
{
    const [locationSearch, setLocationSearch] = useState('');
    const locationSearchValues = locationSearch?search_values.locations.filter(i=>i.title.toLowerCase().indexOf(locationSearch)==0):null;

    const locationField = useRef(null);

    const onAddLocation = useCallback((item)=>{
        setLocationSearch('');
        locationField.current.value = item||'';
    },[]); 

    return (
        <div className="tourguide">
            <Header/>
            <HeroResults area="tourguide"/>
            <div className="page">
                <div className="form01">
                    <div className="row top">
                        <div className="cell first">
                            <img src={Photo}/>
                        </div>
                        <div className="cell second">
                            <Label css={{"width":"270px"}}>Name:<i>*</i></Label>
                            <Label css={{"width":"180px"}}>Middle Name</Label>
                            <Label css={{"width":"250px", "marginRight": "0px"}}>Surname</Label>
                            
                            <TextField type="2" css={{"width":"270px"}}/>
                            <TextField type="2" css={{"width":"180px"}}/>
                            <TextField type="2" css={{"width":"250px"}}/>

                            <Label type="2" css={{"width":"740px"}}>Tell people about yourlself:</Label>
                            <TextArea css={{"width":"740px", "height" : "260px" }}/>                            
                        </div>
                    </div>


                    <div className="row">
                        <div className="cell first">Language:<i>*</i></div><div className="cell second"><Select type="2" css={{"width":"450px"}} placeholder="" values={["EN","HE"]}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Location:<i>*</i></div>
                        <div className="cell second">
                            <TextField type="2" css={{"width":"450px"}} inputRef={locationField} onInputKeyUp={(e)=>setLocationSearch(e.target.value.toLowerCase())} onInputFocus={(e)=>{onAddLocation('')}}/>
                            {locationSearchValues && locationSearchValues.length>0 && <ul className="results-box">
                                    {locationSearchValues.map((i,k)=><li key={k} onClick={()=>onAddLocation(i.title)}>{i.title}</li>)}
                            </ul>}
                        </div>
                    </div>
                    <div className="row"><h4>Please add at least one social network:<i>*</i></h4></div>                    
                    <div className="row">
                        <div className="cell first">Facebook:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Instagram:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">YouTube:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">LinkedIn:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>

                    <div className="row">
                        <div className="cell first">&nbsp;</div><div className="cell second submit">
                            <Button type="2" css={{"width":"450px"}} pathname="/tourguide3" label="Continue to Next Step"/>
                        </div>
                    </div>


                </div>                
                <img src={TourGuidePage_02}/>
            </div>
            <Footer/>
        </div>
    )
}

export default TourGuide02;