import React from 'react';
import {Link} from "react-router-dom";
import style from './TourGuide.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import TourGuidePage_01 from './assets/TourGuidePage_01.jpg'
import Counter from './components/Form/Counter';

const TourGuide01 = () => 
{
    return (
        <div className="tourguide">
            <Header/>
            <HeroResults area="tourguide"/>
            <div className="page">
                <img src={TourGuidePage_01}/>
                <Link to="/tourguide2"><div className="continue-button"></div></Link>
            </div>
            <Footer/>
        </div>
    )
}

export default TourGuide01;