import React, {useState, useCallback, useRef} from 'react';
import {Link} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import search_values from './data/search_engine.json';
import Counter from '../Form/Counter';
import {languages} from '../../constants/constants';
import DatePicker from '../Form/DatePicker';

import styles from './style.css';

const SearchEngine = () => 
{
    const [locations, setLocations] = useState([]);
    const [tours, setTours] = useState([]);
    const [locationSearch, setLocationSearch] = useState('');
    const [languageSearch, setLanguageSearch] = useState('');
    const [tourSearch, setTourSearch] = useState('');
    const [isAdvanced, setAdvanced] = useState(false);

    const locationField = useRef(null);
    const languageField = useRef(null);
    const tourFiled = useRef(null);

    const onDeleteLocation = useCallback((item)=>{
        setLocations(l=>l.filter(i=>i.title!=item.title));
    },[]);

    const onAddLocation = useCallback((item)=>{
        setLocations(l=>[...l,...[item]]);
        setLocationSearch('');
        locationField.current.value = '';
        locationField.current.focus();
    },[]);

    const onBlurLocation = useCallback(()=>{
        setLocationSearch('');
        locationField.current.value = '';
        
    },[]);    

    const onDeleteTour = useCallback((item)=>{
        setTours(l=>l.filter(i=>i.title!=item.title));
    },[]);

    const onAddTour = useCallback((item)=>{
        setTours(l=>[...l,...[item]]);
        setTourSearch('');
        tourFiled.current.value = '';
        tourFiled.current.focus();
    },[]);

    const onBlurTour = useCallback(()=>{
        setTourSearch('');
        tourFiled.current.value = '';
    },[]);    

    const onAddLanguage = useCallback((item)=>{
        setLanguageSearch('');
        languageField.current.value = item||'';
    },[]); 

    const locationSearchValues = locationSearch?search_values.locations.filter(i=>i.title.toLowerCase().indexOf(locationSearch)==0):null;
    const tourSearchValues = tourSearch?search_values.tours.filter(i=>i.title.toLowerCase().indexOf(tourSearch)>-1):null;
    const languageSearchValues = languageSearch?languages.filter(i=>i.toLowerCase().indexOf(languageSearch)>-1):null;

    return (
        <div className={`search-engine${isAdvanced?' advanced':''}`}>
            <div className="container">
                <div className="location-field">
                    <div className="chips">
                        {locations.map((i,k)=>{
                                return <Chip key={k} label={i.title} onDelete={()=>onDeleteLocation(i)} color="primary" />
                        })}
                    </div>
                    <div className="location-search">
                        <input onKeyUp={(e)=>setLocationSearch(e.target.value.toLowerCase())} onFocus={onBlurLocation} ref={locationField} placeholder={locations.length==0?"Where do yo go?":""}/>
                        {locationSearchValues && locationSearchValues.length>0 && <ul className="results-box">
                            {locationSearchValues.map((i,k)=><li key={k} onClick={()=>onAddLocation(i)}>{i.title}</li>)}
                        </ul>}
                    </div>
                </div>
                <div className="search-field">
                    <div className="chips">
                        {tours.map((i,k)=>{
                                return <Chip key={k} label={i.title} onDelete={()=>onDeleteTour(i)} color="primary" />
                        })}
                    </div>
                    <div className="tour-search">
                        <input onKeyUp={(e)=>setTourSearch(e.target.value.toLowerCase())} onFocus={onBlurTour} ref={tourFiled} placeholder="What are you looking for?"/>
                        {tourSearchValues && tourSearchValues.length>0 && <ul className="results-box">
                                {tourSearchValues.map((i,k)=><li key={k} onClick={()=>onAddTour(i)}>{i.title}</li>)}
                        </ul>}
                    </div>

                </div>
                <Link to={{pathname: "/results/"}}>
                    <button className="search-button"/>
                </Link>
                <div className="advanced-container">
                    <div className="row">
                        <div className="cell">
                            <div className="title">How many are you?</div>
                            <div className="filter">
                                <Counter label="Adults (16+)" labelWidth="90px"></Counter>
                                <Counter label="Kids (0-15)" labelWidth="90px"></Counter>
                            </div>
                        </div>
                        <div className="cell">
                            <div className="title">When do you go?</div>
                            <div className="filter">
                                <DatePicker css={{"width":"210px"}} placeholder="Choose date"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cell">
                            <div className="title">Language</div>
                            <div className="filter">
                                <input onKeyUp={(e)=>setLanguageSearch(e.target.value.toLowerCase())} onFocus={(e)=>{onAddLanguage('')}} style={{"width": "210px"}} placeholder="Choose language" ref={languageField}/>
                                {languageSearchValues && languageSearchValues.length>0 && <ul className="results-box">
                                    {languageSearchValues.map((i,k)=><li key={k} onClick={()=>onAddLanguage(i)}>{i}</li>)}
                                </ul>}                                
                            </div>
                        </div>
                        <div className="cell">
                            <div className="title">Keywords</div>
                            <div className="filter"><input style={{"width": "345px"}} placeholder="Enter keywords"/></div>
                        </div>
                    </div>
                </div>
            </div>
            <a className="advanced" onClick={(e)=>{setAdvanced(!isAdvanced)}}>{isAdvanced?'Normal Search':'Advanced Search'}</a>
        </div>
    )
}

export default SearchEngine;