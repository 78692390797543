import React from 'react';
import styles from './style.css'

const Footer = () => 
{
    return (
        <div className="footer"></div>
    )
}

export default Footer;