import React, {useState} from 'react';
import "./style.css";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import 'moment/locale/en-gb';

const DatePicker = ({type, css, placeholder}) => {
  const [day, setDay] = useState(null);
  return(
    <DayPickerInput 
      classNames={{"container":`ra-datepicker style-${type||'1'}`,"overlayWrapper" : "DayPickerInput-OverlayWrapper", "overlay" : "DayPickerInput-Overlay"}} 
      style={css} 
      onDayChange={setDay} placeholder={placeholder}
      formatDate={formatDate}
      parseDate={parseDate}
      dayPickerProps={{
        locale: 'en-gb',
        localeUtils: MomentLocaleUtils,
      }}        
      />
  )
};

export default DatePicker;
