import React from 'react';
import "./style.css";

const Select = ({type, css, placeholder, defaultValue, values}) => {
  return(
    <select className={`ra-select style-${type||'1'}`} style={css} defaultValue={defaultValue||placeholder}>
      {placeholder&&<option className="placeholder">{placeholder}</option>}
      {values.map((i,k)=><option key={k}>{i}</option>)}
    </select>
  )
};

export default Select;
