import React from 'react';
import ReactDOM from 'react-dom';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import "./style.css";

const Phone = ({type, css}) => {
  return(
    <IntlTelInput containerClassName={`ra-phone intl-tel-input style-${type||'1'}`} separateDialCode={true} style={css}/>
  )
};

export default Phone;
