import React from 'react';
import "./style.css";

const Modal = (props) => {
  return(
    <div className={`ra-modal ${props.show?'show':''}`}><div className="ra-modal-content" style={props.contentCss}>{props.children}</div></div>
  )
};

export default Modal;
