import React from 'react';
import {Link} from "react-router-dom";
import style from './TourGuide.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import TourGuidePage_06 from './assets/TourGuidePage_06.jpg'
import Counter from './components/Form/Counter';

const TourGuide06 = () => 
{
    return (
        <div className="tourguide">
            <Header/>
            <HeroResults area="tourguide"/>
            <div className="page">
                <img src={TourGuidePage_06}/>
            </div>
            <Footer/>
        </div>
    )
}

export default TourGuide06;