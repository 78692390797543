import React from 'react';
import {Link} from "react-router-dom";
import "./style.css";

const Button = ({type, css, label, pathname, onButtonClick}) => {
  var ButtonObj = <button className={`ra-button style-${type||'1'}`} style={css} onClick={onButtonClick}>{label}</button>;
  
  
  if(pathname){
    return(
      <Link to={{pathname}}>
          {ButtonObj}
      </Link>
    )  
  }

  return(ButtonObj);
};

export default Button;
