import React from 'react';
import styles from './style.css';
import SearchField from './assets/SearchField.png';
import SearchEngine from '../SearchEngine'

const HeroResults = ({area}) => 
{
    return (
        <div className={`hero-results ${area||"results"}`}>
            <div className="search">
                <img src={SearchField}/>
            </div>
            <div className="summary">
                {area=="results" && <div className="info">
                    <h3>Search results for:</h3>
                    <span><span>London, Paris</span> | 12.4.2021 - 17.4.2021  |  2 Adults  |  English, French</span><br/>
                    <span>Wine, Food, Art</span><br/>
                </div>}
                {area=="tour" && <div className="info">
                    <h3>Candy Shop Tour</h3>
                    <span>12.4.2021 - 17.4.2021  |  2 Adults  |  English, French</span><br/>
                    <span>Feel Like a Child with Lee Chen</span><br/>
                </div>}
                {area=="member-area" && <div className="info">
                    <h3><br/>Member Area</h3>
                </div>}
                {area=="tourguide" && <div className="info">
                    <h3>Hellow New Tour Guide</h3>
                    <span>Time to know you better</span>
                </div>}                
            </div>
        </div>
    )
}

export default HeroResults;