import React from 'react';
import style from './Homepage.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroMain from './components/HeroMain';
import Homepage_01 from './assets/Homepage_01.jpg'

const Homepage = () => 
{
    return (
        <div className="homepage">
            <Header/>
            <HeroMain/>
            <div className="page"><img src={Homepage_01}/></div>
            <Footer/>
        </div>
    )
}

export default Homepage;
