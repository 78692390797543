import React from 'react';
import styles from './style.css';
import about from './assets/About_2.png';
import SearchEngine from '../SearchEngine'

const HeroMain = () => 
{
    return (
        <div className="hero-main">
            <div className="search">
                <h1>Find the most interesting, unique<br/>and fascinating tours</h1>
                <SearchEngine/>
            </div>
            <div className="about">
                <div className="video-container">
                    <video width="100%" height="100%" controls>
                        <source src="ra.mp4" type="video/mp4"/>
                    </video>
                </div>
                <img src={about}/>
            </div>
        </div>
    )
}

export default HeroMain;