import React, {useState} from 'react';
import {Link} from "react-router-dom";
import style from './TourInfo.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import TourPage_01 from './assets/TourPage_01.jpg'
import Counter from './components/Form/Counter';
import TextField from './components/Form/TextField';
import Select from './components/Form/Select';
import Checkbox from './components/Form/Checkbox';
import TextArea from './components/Form/TextArea';
import Phone from './components/Form/Phone';
import Button from './components/Form/Button';
import DatePicker from './components/Form/DatePicker';
import Modal from './components/Modal';

const TourInfo = () => 
{
    const [modal, setModal] = useState(false);
    
    return (
        <div className="tour-info">
            <Header/>
            <HeroResults area="tour"/>
            <div className="page">
                <img src={TourPage_01}/>
                <a className="book-a-tour" href="#form"></a>
                <div className="form" id="form">
                    <h3>Book This Tour</h3>
                    <h6>Candy Shop | London | Lee Chen</h6>

                    <div className="row">
                        <div className="cell" style={{"width":"74%"}}>
                            <TextField css={{"width":"450px"}} placeholder="Your Full Name"/>
                            <DatePicker css={{"width":"215px"}} placeholder="Date"/>
                            <Select css={{"width":"215px"}} placeholder="Time:" values={["12:00 PM","14:00 PM","16:00 PM"]}/>
                        </div>
                        <div className="cell" style={{"width":"25%"}}>
                            <Counter label="Adults" labelWidth="70px" view="form-counter"></Counter>
                            <Counter label="Kids" labelWidth="70px" view="form-counter"></Counter>
                            <Counter label="Babies" labelWidth="70px" view="form-counter"></Counter>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cell" style={{"width":"45%"}}>
                            <Phone/>
                            <Select css={{"width":"295px"}} placeholder="Tour Duration:" values={["2 Hours","4 Hours"]}/>
                            <Select css={{"width":"295px"}} placeholder="I'm From:" values={["Israel","United States"]}/>
                            <Checkbox label={"One or more are senior (above 65)"}/>
                            <Checkbox label={"One or more are disabled)"}/>
                        </div>
                        <div className="cell" style={{"width":"54%", "textAlign":"center"}}>
                            <TextArea css={{"width":"415px", "height" : "260px" }} placeholder="Special Requests:"/>
                            <Button label={"BOOK THIS TOUR"} onButtonClick={()=>{setModal(true)}}/>
                            <Modal show={modal} contentCss={{"width" : "350px"}}>
                                <h4>Congrats! You've booked this tour</h4>
                                <br/>
                                <Button label={"Go To Your Bookings"} pathname={"/bookings/"} type="4"/>&nbsp;&nbsp;<Button label={"Search For More Tours"} pathname={"/results/"}/>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default TourInfo;