import React from 'react';
import "./style.css";

const TextField = ({type, css, placeholder, password, inputRef, onInputKeyUp, onInputFocus}) => {
  return(
    <input type={password?"password":"text"} className={`ra-text style-${type||'1'}`} style={css} placeholder={placeholder} ref={inputRef} onKeyUp={onInputKeyUp} onFocus={onInputFocus}/>
  )
};

export default TextField;
