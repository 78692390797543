import React from 'react';
import style from './MyBookings.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import MyBookings_01 from './assets/MyBookings_01.jpg'

const MyBookings = () => 
{
    return (
        <div className="my-bookings">
            <Header/>
            <HeroResults area="member-area"/>
            <div className="page">
                <img src={MyBookings_01}/>
            </div>
            <Footer/>
        </div>
    )
}

export default MyBookings;