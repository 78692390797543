import React from 'react';
import "./style.css";

const TextArea = ({type, css, placeholder}) => {
  return(
    <textarea className={`ra-textarea style-${type||'1'}`} style={css} placeholder={placeholder}></textarea>
  )
};

export default TextArea;
