import React from 'react';
import {Link} from "react-router-dom";
import style from './SearchResults.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import ResultsPage_01 from './assets/ResultsPage_01.jpg'

const SearchResults = () => 
{
    return (
        <div className="results">
            <Header/>
            <HeroResults area="results"/>
            <div className="page">
                <Link to={{pathname: "/tour/"}}>
                    <div className="tour"></div>
                </Link>                
                <img src={ResultsPage_01}/>
            </div>
            <Footer/>
        </div>
    )
}

export default SearchResults;
