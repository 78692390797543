import { MuiThemeProvider } from 'material-ui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import ScrollToTop from './mockup/components/Router/ScrollToTop';

import './index.css';

import Homepage from './mockup/Homepage';
import SearchResults from './mockup/SearchResults';
import TourInfo from './mockup/TourInfo';
import MyBookings from './mockup/MyBookings';

import TourGuide01 from './mockup/TourGuide01';
import TourGuide02 from './mockup/TourGuide02';
import TourGuide03 from './mockup/TourGuide03';
import TourGuide04 from './mockup/TourGuide04';
import TourGuide05 from './mockup/TourGuide05';
import TourGuide06 from './mockup/TourGuide06';



const ra_theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#35a9da',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#247698',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


ReactDOM.render(
  <ThemeProvider theme={ra_theme}>
    <React.StrictMode>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/tourguide1/"><TourGuide01 /></Route>
            <Route path="/tourguide2/"><TourGuide02 /></Route>
            <Route path="/tourguide3/"><TourGuide03 /></Route>
            <Route path="/tourguide4/"><TourGuide04 /></Route>
            <Route path="/tourguide5/"><TourGuide05 /></Route>
            <Route path="/tourguide6/"><TourGuide06 /></Route>
            
            <Route path="/tour/"><TourInfo /></Route>
            <Route path="/results/"><SearchResults /></Route>
            <Route path="/bookings/"><MyBookings/></Route>
            <Route path="/"><Homepage /></Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
);