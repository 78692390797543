import React, { useState } from 'react';
import {Link} from "react-router-dom";
import style from './TourGuide.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroResults from './components/HeroResults';
import TourGuidePage_03 from './assets/TourGuidePage_03.jpg'
import secure from './assets/secure.png'

import Label from './components/Form/Label';
import Counter from './components/Form/Counter';
import TextField from './components/Form/TextField';
import Select from './components/Form/Select';
import Checkbox from './components/Form/Checkbox';
import TextArea from './components/Form/TextArea';
import Phone from './components/Form/Phone';
import File from './components/Form/File';
import Button from './components/Form/Button';

const TourGuide03 = () => 
{
    return (
        <div className="tourguide">
            <Header/>
            <HeroResults area="tourguide"/>
            <div className="page">
            <div className="form02">
                    <div className="row">
                        <div className="cell first">Username:<i>*</i></div><div className="cell second"><Label type="2" css={{"width":"270px"}}>LeeChen</Label></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Password:<i>*</i></div><div className="cell second"><TextField type="2" css={{"width":"450px"}} password={true}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Email:</div><div className="cell second"><Label type="2" css={{"width":"270px"}}>leechen@test.com</Label></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Street:<i>*</i></div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Street:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">City:<i>*</i></div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">State:</div><div className="cell second"><TextField type="2" css={{"width":"450px"}}/></div>
                    </div>
                    <div className="row">
                        <div className="cell first">Country:</div><div className="cell second"><Select type="2" css={{"width":"450px"}} values={["United States","Israel"]}/></div>
                    </div>

                    <div className="row">
                        <div className="cell first">Mobile:<i>*</i></div><div className="cell second"><Phone type="2" css={{"width":"450px"}}/></div>
                    </div>

                    <div className="row">
                        <div className="cell first">KYD:<i>*</i></div><div className="cell second"><File type="2" css={{"width":"450px"}}/><img src={secure} style={{"verticalAlign":"top", "marginLeft" : "20px"}}/></div>
                    </div>


                    <div className="row">
                        <div className="cell first">&nbsp;</div><div className="cell second submit">
                            <Button type="2" css={{"width":"450px"}} pathname="/tourguide4" label="Continue to Next Step"/>
                        </div>
                    </div>


                </div>       
                <img src={TourGuidePage_03}/>
            </div>
            <Footer/>
        </div>
    )
}

export default TourGuide03;